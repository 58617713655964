<template>
  <div v-if="show" class="slide fixed w-full pt-2 px-2" style="top: 0px;" align="center">
    <div class="bg-ivory rounded-xl border border-nota-pink p-2 max-w-full" style="width: 400px;">
      {{ message }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: Boolean,
  message: String,
});
const emit = defineEmits(['update:modelValue'])
const show = computed({ 
  get: () => props.modelValue, 
  set: (value) => emit('update:modelValue', value)
})
watch(show, (value) => {
  if (value) {
    setTimeout(() => {
      show.value = false;
    }, 3000);
  }
})
</script>

<style>
@keyframes slidein {
  from {
    left: -50%;
  }
  to {
    left: 0px;
  }
}
.slide {
  animation: slidein 0.5s forwards;
}
</style>
